.dropdown {
    position: relative;

    &-toggle {
        &:hover {
            & + .dropdown-items {
                display: block;
            }
        }
    }

    &-items {
        position: absolute;
        display: none;
        width: 100%;
        list-style: none;
        background-color: #090909;

        &:hover {
            display: block;
        }

        &-item {
            width: 100%;

            & a {
                width: 100%;
                display: flex;
                box-sizing: border-box;
            }
        }
    }
}
