* {
    margin: 0;
    padding: 0;
}

#bloc {
    display: grid;
    grid-template-areas: "one two three four";
    overflow-y: hidden;
    overflow-x: hidden;
}

.box {
    height: 100vh;
    width: 1920px;
    position: relative;
}

@keyframes olivier {
    from {
        top: 15%;
    }
    to {
        top: 20%;
    }
}

@keyframes thomas {
    from {
        top: 15%;
    }
    to {
        top: 18%;
    }
}

.firstBox {
    grid-area: one;
    background-color: #001b11;

    & .noScalePalmares,
    .scaleOnePalmares,
    .scaleTwoPalmares {
        font-size: 5.5rem;
        left: 7%;
        position: absolute;
        color: white;
        background-color: #001b11;
        height: 100px;
    }

    & .noScalePalmares {
        z-index: 3;
        top: 15%;
    }

    & .scaleOnePalmares {
        z-index: 2;
        top: 18%;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 2px #038058;
        animation: thomas 0.4s ease;

        & p {
            opacity: 80%;
        }
    }

    & .scaleTwoPalmares {
        z-index: 1;
        top: 20%;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 2px #038058;
        animation: olivier 0.8s ease;

        & p {
            opacity: 40%;
        }
    }

    & .description {
        position: absolute;
        width: 25%;
        font-size: 0.8rem;
        top: 40%;
        left: 20%;
        color: white;
        line-height: 35px;
    }

    & .opacityDate {
        color: #ffffff12;
        font-size: 6.875rem;
        font-weight: bold;
        width: 230px;
        position: absolute;
        top: 52%;
        left: 60%;
        transform: rotate(310deg);
    }

    & .date {
        color: #038058;
        font-size: 2.5rem;
        font-weight: bold;
        width: 230px;
        position: absolute;
        top: 65%;
        left: 60%;
    }

    & .opacityNoneDate {
        opacity: 0;
    }

    & .inOpacityDate {
        transition: 2s;
        opacity: 1;
    }

    & .file {
        height: 1px;
        background-color: white;
        position: absolute;
        top: 800px;
        width: 1152px;
        left: 40%;
    }

    & .fileVerticalCut {
        width: 400px;
    }

    & .fileVerticalIn {
        transition: 2s;
    }

    & .traitHorizontale {
        height: 1px;
        background-color: white;
        position: absolute;
        width: 1px;
        top: 700px;
        left: 1600px;
        height: 200px;
    }

    & .traitHorizontaleNone {
        top: 800px;
        height: 0px;
    }

    & .traitHorizontaleVisible {
        height: 200px;
        transition: 2s;
        top: 600px;
    }

    & .dateWin {
        width: 230px;
        position: absolute;
        top: 850px;
        left: 1500px;
    }
    & .dateWinNone {
        opacity: 0;
        top: 900px;
    }
    & .dateWinVisible {
        opacity: 1;
        transition: 2s;
        top: 850px;
    }
}

.secondBox {
    grid-area: two;
    background-color: #038058;

    & .firstTraitSecondBox {
        height: 1px;
        background-color: white;
        position: absolute;
        top: 800px;
        width: 300px;
    }

    & .secondTraitSecondBox {
        height: 1px;
        background-color: white;
        position: absolute;
        top: 800px;
        width: 600px;
        left: 299px;
        transform-origin: top left;
        transform: rotateZ(-50deg);
    }

    & .thirdTraitSecondBox {
        height: 1px;
        background-color: white;
        position: absolute;
        top: 340px;
        left: 685px;
        width: 1253px;
    }
}

.thirdBox {
    grid-area: three;
    background-color: #001b11;

    & .firstTraitThirdBox {
        height: 1px;
        background-color: white;
        position: absolute;
        top: 340px;
        width: 300px;
    }

    & .secondTraitThirdBox {
        height: 1px;
        background-color: white;
        position: absolute;
        top: 340px;
        width: 600px;
        left: 299px;
        transform-origin: top left;
        transform: rotateZ(50deg);
    }

    & .thirdTraitThirdBox {
        height: 1px;
        background-color: white;
        position: absolute;
        top: 800px;
        left: 684px;
        width: 1236px;
    }
}

.secondBox,
.thirdBox {
    & .invisible {
        width: 0;
    }

    & .visible {
        transition: 3s;
    }
}
