.modal-body-article {
    color: black;
}

.modal-body-article p {
    color: black;
}

.modal-body-article strong {
    color: black;
}

.modal-body-article * {
    color: black;
}

#article-csgo-hill-master-series {
    background-image: url("../../images/csgo_hill_master_series.png");
}

#articlemodal-4,
#articlemodal-5,
#articlemodal-6 {
    background-color: #040c0c;
}

.exampleModalLabel {
    color: black;
}

.poster {
    width: 150px;
}

#league-of-legend {
    background-image: url("../../images/league_of_legend.jpg");
}

#tee_shirt {
    //background-image: url("../../images/shop/maillot_divergente.png");
}

.icon {
    font-size: 50px;
    margin: auto;
    display: block;
}
