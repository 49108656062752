// color
$black: #000;
$black2: #030303;

$black-light-opacity: #000000d1;
$black-light-opacity2: #05100fcc;
$black-light-opacity3: #000000b3;
$black-light-opacity4: #001413e8;
$black-light-opacity5: #000000e1;
$black-light-opacity6: #001413b3;

$white: #fff;
$ligh-bleu: #1dfef6;
$gray: #686868;
$red: red;
$red-light: #ff030340;
