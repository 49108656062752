

#header_visitor {
    height: 120px;
    z-index: 999;
    margin-bottom: 50px;
    .header_visitor_fixed {
        position: fixed;
        width: 100%;
        height: 120px;
        backdrop-filter: blur(7px);
        background-color: #090909de;
        border-bottom: 1px solid var(--blackGreen);
    }

    .nav_container {
        max-width: 1700px;
        margin: 0 auto;
        display: flex;
        height: 100%;
        padding: 0 16px;
        box-sizing: content-box;
        .nav_logo_container {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 128px;
            border-right: 1px solid var(--blackGreen);
            &.nav_logo_container::after {
                content: "";
                position: absolute;
                width: 30px;
                height: 2px;
                background: linear-gradient(90deg, #101111 0%, #101111 25%, transparent 25%, transparent 75%, #101111 75%);
                transform: rotate(90deg);
                right: -15px;
                bottom: 40px;
            }
            .nav_logo {
                & svg {
                    height: 50px;
                    & path {
                        transition: fill .25s;
                        fill: var(--white);
                    }
                }
                &:hover path {
                    fill: var(--green);
                }
            }
        }
        .nav {
            margin-left: auto;
            .nav_items {
                display: flex;
                align-items: center;
                margin: 0;
                grid-gap: 1.5rem;
                list-style: none;
                .nav_item {
                    display: flex;
                    &.nav_animation .nav_item_link::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: -80%;
                        height: 1px;
                        background-color: var(--green);
                        width: 80%;
                        transition: all .25s;
                    }
                    &.nav_animation .nav_item_link:hover::after {
                        left: 0;
                    }
                    &.nav_item_dropdown {
                        position: relative;
                        & .dropdown_menu {
                            visibility: hidden;
                            position: absolute;
                            background-color: var(--black);
                            padding: .5rem;
                            top: 32px;
                            opacity: 0;
                            transition: opacity .25s;
                            & .dropdown_item {
                                list-style: none;
                                &:hover .dropdown_item_link {
                                    color: var(--green);
                                }
                                & .dropdown_item_link {
                                    display: flex;
                                    align-items: center;
                                    font-family: BebasNeue;
                                    letter-spacing: 1px;
                                    text-decoration: unset;
                                    transition: color .25s;
                                    text-wrap: nowrap;
                                    &.active {
                                        color: var(--green);
                                    }
                                    & svg {
                                        margin-bottom: 1px;
                                        transform: rotate(-90deg);
                                    }
                                    &:hover svg path:last-child {
                                        stroke: #32D299;
                                    }
                                }
                            }
                        }
                        &:hover .nav_item_link {
                            color: var(--green);
                        }
                        &:hover .dropdown_menu {
                            visibility: visible;
                            opacity: 1;
                        }
                        & svg path {
                            transition: stroke .25s;
                        }
                    }
                    &:hover.nav_item_dropdown .nav_item_link  svg path:last-child {
                        stroke: #32D299;
                    }
                    &.nav_arround_animation .nav_item_link span {
                        display: block;
                        position: absolute;
                        transition: .10s;
                        background-color: var(--green);
                        &:nth-child(1),
                        &:nth-child(3) {
                            width: 100%;
                            height: 1px;
                        }
                        &:nth-child(2),
                        &:nth-child(4) {
                            height: 100%;
                            width: 1px;
                        }
                        &:nth-child(1) {
                            bottom: 0;
                            left: -100%;
                            transition-delay: .4s;
                        }
                        &:nth-child(2) {
                            right: 0;
                            bottom: -100%;
                            transition-delay: .3s;
                        }
                        &:nth-child(3) {
                            top: 0;
                            right: -100%;
                            transition-delay: .2s;
                        }
                        &:nth-child(4) {
                            left: 0;
                            top: -100%;
                            transition-delay: .1s;
                        }
                    }
                    &.nav_arround_animation .nav_item_link:hover span,
                    &.nav_arround_animation .nav_item_link.active span {
                        &:nth-child(1) {
                            bottom: 0;
                            left: 0;
                            transition-delay: .10s;
                        }
                        &:nth-child(2) {
                            right: 0;
                            bottom: 0;
                            transition-delay: .20s;
                        }
                        &:nth-child(3) {
                            top: 0;
                            right: 0;
                            transition-delay: .30s;
                        }
                        &:nth-child(4) {
                            left: 0;
                            top: 0;
                            transition-delay: .40s;
                        }
                    }
                    &.nav_arround_animation .nav_item_link {
                        padding: 3px 10px;
                        margin-left: -10px;
                    }
                    .nav_item_link {
                        display: inline-block;
                        overflow: hidden;
                        position: relative;
                        font-size: 1.3rem;
                        text-align: center;
                        text-transform: uppercase;
                        font-family: BebasNeue;
                        letter-spacing: 1px;
                        transition: all .25s;
                        text-decoration: none;
                        &:hover {
                            color: var(--green);
                        }
                        &.active {
                            color: var(--green);
                            &::after {
                                left: 0;
                            }
                            & svg path:last-child {
                                stroke: #32D299;
                            }
                        }
                    }
                }
            }
        }
        .nav_burger_btn {
            display: none;
            flex-direction: column;
            justify-content: space-between;
            width: 30px;
            height: 25px;
            margin: auto 0 auto auto;
            transition: transform .25s;
            cursor: pointer;
            &.active {
                transform: rotate(-45deg);
            }
            &.active span:first-child {
                transform: rotate(-90deg) translateX(3px);
            }
            &.active span:last-child {
                transform: rotate(-90deg) translateX(-3px);
            }
            & span {
                display: block;
                height: 3px;
                border-radius: 2px;
                background-color: #FFF;
                &:first-child, &:last-child {
                    width: 50%;
                }
                &:first-child {
                    transform-origin: right;
                    transition: transform .25s;
                }
                &:last-child {
                    align-self: flex-end;
                    transform-origin: left;
                    transition: transform .25s;
                }
            }
        }
    }
    .nav_mobile {
        overflow: hidden;
        max-height: 0;
        overflow: hidden;
        transition: max-height .25s;
        border-bottom: 1px solid var(--blackGreen);
        background-color: #090909de;
        & .nav_mobile_menu {
            padding: 1rem;
            & .nav_mobile_item {
                list-style: none;
                text-align: center;
                &:hover .nav_mobile_item_link {
                    color: var(--green);
                }
                & .nav_mobile_item_link {
                    text-decoration: none;
                    font-family: BebasNeue;
                    font-size: 1.3rem;
                    transition: color .25s;
                    letter-spacing: 1px;
                    &.active {
                        color: var(--green);
                    }
                    &.active svg path:last-child {
                        stroke: #32D299;
                    }
                }
            }
            & .nav_mobile_dropdown {
                & svg path {
                    transition: stroke .25s;
                }
                &:hover svg path:last-child {
                    stroke: var(--green);
                }
                & .nav_mobile_dropdown_menu {
                    max-height: 0;
                    transition: max-height .25s;
                    overflow: hidden;
                    padding: 0;
                    & .nav_mobile_dropdown_item {
                        list-style: none;
                        text-align: center;
                        &:hover .nav_mobile_dropdown_item_link {
                            color: var(--green);
                        }
                        & .nav_mobile_dropdown_item_link {
                            text-decoration: none;
                            font-family: BebasNeue;
                            letter-spacing: 1px;
                            transition: color .25s;
                            &.active {
                                color: var(--green);
                            }
                        }
                    }
                }
            }
        }
    }
    &.active .nav_mobile {
        margin-top: 1px;
    }
}



@media screen and (max-width: 700px) {
    #header_visitor .header_visitor_fixed .nav {
        display: none;
    }
    #header_visitor .nav_container .nav_burger_btn {
        display: flex;
    }
}
@media screen and (min-width: 700px) {
    #header_visitor.active .nav_mobile {
        display: none;
    }
}