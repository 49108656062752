#container-login-form {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;

    & .logo {
        position: absolute;
        height: 100vh;
        z-index: -1000;
        opacity: 0.75;
    }

    & form {
        width: 300px;
    }
}

#admin {
    width: 100%;
    background-color: #181818;
    & .content {
        padding: 1em;
    }
}

.content {
    & .action {
        padding: 2em 0;
    }
}
