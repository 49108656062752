/*EVENEMENT*/

#evenements .content.sbc,
#select-evenement .content.sbc {
    margin-top: -50px;
    position: relative;
}

#evenements {
    & .content.sbc .mainContainer {
        & .all-evenements {
            & a {
                text-decoration: none;

                &:first-child article {
                    margin-top: 40px;
                }
            }

            & article:before {
                background: linear-gradient(90deg, $black-light-opacity4 26%, $black2 100%) !important;
                z-index: -1;
            }

            & .evenements {
                display: flex;
            }
        }

        & article {
            /*Style des évènements*/
            cursor: pointer;
            margin: 30px 0;
            background-image: url("../../images/banniere_2.jpg");
            background-position: center;
            position: relative;
            z-index: 1;
            transition: 0.25s;
            background-size: 100%;
            height: 90px;
            align-items: center;

            & .evenements-title {
                padding-left: 15px;
            }

            & .evenements-title,
            & .evenements-title p {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-left: 15px;
            }

            & .evenements-time {
                align-items: center;
                background: $ligh-bleu;
                min-width: 90px;
                height: 100%;
                text-align: center;
                display: flex;
                justify-content: center;

                & div {
                    color: black;
                }
            }

            & .evenements-time,
            & .evenements-title p:first-child {
                /*title evemements*/
                font-size: 20px;
                font-weight: bold;
            }

            &:hover {
                /*animation hover evenement*/
                transform: translateY(-5px);
                transition: 0.25s;
            }

            & p {
                margin: 0;
            }
        }

        & button {
            margin: auto;
            border: 2px solid $ligh-bleu;
            display: block;
            border-radius: 0;
            padding: 20px;
            font-weight: bold;
            color: $white;
        }
    }
}

/*scroll bar evenements*/

section .all-evenements::-webkit-scrollbar,
#team .equipe-team::-webkit-scrollbar {
    /* width */
    width: 10px;
}

#team {
    & .equipe-team {
        flex-wrap: wrap;
        width: 50%;

        @include tablet {
            flex-wrap: wrap;
            width: 75%;
            margin: auto;
        }
    }

    & section h6 {
        text-align: center;
        margin: 20px 0;
        font-size: 20px;
    }
}

section .all-evenements::-webkit-scrollbar-track,
#team .equipe-team::-webkit-scrollbar-track {
    /* Track */
    background: $white;
    border-radius: 10px;
    padding: 20px;
}

section .all-evenements::-webkit-scrollbar-thumb,
#team .equipe-team::-webkit-scrollbar-thumb {
    /* Handle */
    background: $ligh-bleu;
    border-radius: 10px;
    margin-left: 50px;
    padding: 20px;
}

section .all-evenements::-webkit-scrollbar-thumb:hover,
#team .equipe-team::-webkit-scrollbar-thumb:hover {
    /* Handle on hover */
    background: $ligh-bleu;
}

/*roster*/

.roster {
    & .head-roster {
        text-align: center;
        margin: 30px 0;
    }

    & .head-roster img,
    & .vs-roster .head-roster {
        width: 120px;
        height: 120px;
    }

    & .vs-roster .head-roster {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 30px auto;
    }

    & .head-roster span {
        font-size: 40px;
    }
}
