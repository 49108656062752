/*CONTENT*/

section {
    & .dropdown {
        margin-bottom: 20px;

        & > a:hover,
        & > a,
        & > a:focus,
        & > a.btn-secondary:not(:disabled):not(.disabled):active,
        &.show > .btn-secondary.dropdown-toggle:focus,
        &.show > .btn-secondary.dropdown-toggle {
            /*style button dropdown*/
            background: transparent;
            border: 2px solid $ligh-bleu;
            border-radius: 0;
            box-shadow: none;
        }

        & a {
            /*dropdown button right side*/
            padding: 20px;
            padding-left: 15px;
            font-weight: bold;
            display: block;
            width: 185px;
            margin: 0 0 0 auto;
        }

        &.show {
            & .dropdown-menu.show {
                /*style dropdown open*/
                background: $black;
                border: 2px solid $ligh-bleu;
                border-radius: 0;
                top: -4px !important;
                margin: 0;
                padding: 0;

                & .dropdown-item {
                    /*style dropdown open link*/
                    color: $white;
                    transition: 0.25s;

                    &:hover {
                        background: $white;
                        color: $black;
                        transition: 0.25s;
                    }
                }
            }
        }
    }

    & .all-evenements {
        justify-content: space-between;
    }

    /*article news*/

    & .all-news {
        & .newsWrapper {
            width: 20%;

            & article {
                & .category,
                & .title {
                    font-weight: bold;
                }

                &.category {
                    color: $gray;
                }

                & .preview-btn {
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    right: 0;
                    bottom: 0;
                    background: $ligh-bleu;

                    & i {
                        font-size: 50px;
                        margin: auto;
                        display: block;
                    }
                }

                &:hover {
                    /*animation hover news*/
                    transform: scale(1.05);
                    transition: 0.25s;
                }

                &::before {
                    /*rgb style article and evenement*/
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: $black-light-opacity2;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: -1;
                }
            }
        }
    }
}
