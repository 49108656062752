.loadingPageContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    & .loading {
        display: flex;
        height: -moz-calc(100% - 5em);
        height: -webkit-calc(100% - 5em);
        height: calc(100% - 5em);
        &__wrapper {
            padding: 1em;
            display: flex;
            flex: auto;
            align-items: center;
            justify-content: center;
        }
    }
}
