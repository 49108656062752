.wrapperBanner {
    position: relative;

    & span {
        /*Bannière d'accueil*/
        background-size: cover;
        background-position: center;
        background-image: url("../../images/banniere_final_2021_twitter.png");
        width: 100%;
        height: 600px;
        display: block;
        box-shadow: inset 0 72px 85px $black;
    }

    &-title {
        margin-bottom: 8px;
        margin-top: 0;
        font-size: 2.5rem;
        line-height: 1.2;
    }

    &-subTitle {
        margin-bottom: 8px;
        margin-top: 0;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.2;
    }

    &.--globalBanner span {
        /*Bannière sur l'ensemble des autres page*/
        background-image: url("../../images/banniere_2.jpg");
        height: 350px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;

        & h1 {
            color: $ligh-bleu;
            font-weight: bold;
        }

        &::after {
            content: "";
            background: linear-gradient(to bottom, transparent 0%, $black 100%);
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            height: 85px;
        }
    }
}
