@font-face {
    font-family: theBoldFont;
    src: url(../../font/THEBOLDFONT.ttf);
}
@font-face {
    font-family: theBoldFont2;
    src: url(../../font/THEBOLDFONT2022.otf);
}
@font-face {
    font-family: BebasNeue;
    src: url(../../font/BebasNeue-Regular.ttf);
}
@font-face {
    font-family: HumanBold;
    src: url(../../font/HumanBold.otf);
}

/*-----------------------------------*/
// Couleur
/*-----------------------------------*/
:root {
    --green: #32D299;
    --blackGreen: #28413B;
    --lightBlack: #111;
    --black: #090909;
    --white: #FFF;
    --gray: #D3D3D3;
    --greenSVG: invert(100%) sepia(44%) saturate(3700%) hue-rotate(77deg) brightness(95%) contrast(71%);
}


#root > div {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
}


*,
a,
a:hover {
    color: $white;
}


/*-----------------------------------*/
// Container
/*-----------------------------------*/
.mediumContainer {
    max-width: 1475px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
}
.smallContainer {
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
}


.mainContainer {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 16px;
    width: 100%;
}

.bigContainer {
    max-width: 2000px;
    margin: 0 auto;
}



/*-----------------------------------*/
// Title
/*-----------------------------------*/
.title {
    font-family: BebasNeue;
    text-transform: uppercase;
    &.big_title {
        font-size: 55px;
        line-height: 60px;
        margin: 0;
    }
    &.little_title {
        font-size: 17px;
        margin: 0;
        letter-spacing: .35rem;
        color: var(--green);
    }
}


/*-----------------------------------*/
// Button
/*-----------------------------------*/
.btn_perso {
    display: inline-block;
    padding: .5rem 1rem;
    text-decoration: none;
}

.white_btn {
    font-family: BebasNeue;
    text-transform: uppercase;
    border-radius: 0;
    background-color: var(--white);
    color: var(--black);
    z-index: 1;
    &:hover {
        color: var(--black);
    }
}



.upp {
    text-transform: uppercase;
}

.green_text {
    color: var(--green);
}

body {
    // display: flex;
    // flex-direction: column;
    // min-height: 100vh;
    background: var(--lightBlack);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    margin: 0;
}

main {
    // min-height: calc(100vh - 84px);
}

.text-center {
    text-align: center;
}

.margin-V-20 {
    margin: 20px 0px;
}

.font-size-20 {
    font-size: 20px;
}

.display-none {
    display: none;
}

/*Back Page*/

.back-page {
    border: 2px solid $ligh-bleu;
    display: inline-block;

    & div {
        display: inline-block;
        padding: 20px;
        font-weight: bold;
        width: 174.73px;
        text-align: center;
        cursor: pointer;
    }
}

.flex-center {
    margin-top: auto;
    margin-bottom: auto;
}

.pageTitle {
    color: white;
}

select,
input {
    color: black;
    max-width: 25%;
    min-width: 25%;
}

textarea {
    min-width: 50%;
    max-width: 50%;
}

.fileUploader {
    max-width: 25%;
    min-width: 25%;
}

.imgWidth100px {
    max-width: 100px;
}
.blockCenter {
    display: flex;
    justify-content: center;
}


/* ----------------------------------------- */
// SPONSOR
/* ----------------------------------------- */
.sponsor_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    position: relative;
    overflow: hidden;
    background-color: #0e0e0e;
    margin: 20px 0;
    text-transform: uppercase;
    font-family: BebasNeue;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    height: 70px;
    &.sponsorToRight .sponsorTrack {
        margin-left: -600px;
        animation: sponsorCarouselToRight 15s linear infinite;
    }
    &.sponsorToLeft .sponsorTrack {
        animation: sponsorCarouselToLeft 10s linear infinite;
    }
    & .sponsorTrackContainer {
        max-width: 1903px;
        margin: 0 auto;
        overflow: hidden;
        & .sponsorTrack {
            display: flex;
            & .sponsorContainerItem {
                display: flex;
                align-items: center;
                & .sponsorItem {
                    height: 25px;
                    width: 100px;
                }
                & .sponsorSeparator {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: BebasNeue;
                    font-size: 25px;
                    height: 50px;
                    line-height: 29px;
                    width: 100px;
                }
                & .sponsorSeparatorTxt {
                    width: 200px;
                }
            }
        }   
    }
}
@keyframes sponsorCarouselToLeft {
	0% {
		transform: translateX(0);
	} 100% {
		transform: translateX(-400px);
	}
}
@keyframes sponsorCarouselToRight {
	0% {
		transform: translateX(0);
	} 100% {
		transform: translateX(600px);
	}
}


.color_effect {
    width: 500px;
    height: 500px;
    position: absolute;
    background: radial-gradient(ellipse at center, 
    rgba(40, 65, 59, .9) 0%, 
    rgba(40, 65, 59, .6) 20%, 
    rgba(40, 65, 59, 0) 70%, 
    rgba(40, 65, 59, 0) 
    100%);
    z-index: -1;
    &.left_top {
        left: -250px;
        top: -125px;
    }
    &.right_bottom {
        bottom: -300px;
        right: -600px;
    }
}