.adminHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    width: 14vw;

    &__wrapper {
        display: flex;
        flex-direction: column;
        flex: auto;

        &-logo {
            height: 5em;
        }
    }

    & img {
        padding: 1em;
        width: 14vw;
    }
}
