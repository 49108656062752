.twitchContainer {
    display: flex;
    justify-content: center;
}

.btnSwapRight {
    display: flex;
    align-items: center;
    margin-left: 30px;

    & .btnChevron {
        height: 50px;
        width: auto;
        cursor: pointer;
    }
}

.twitchStream {
    transition: transform 2s, left 2s;
    position: relative;
}
