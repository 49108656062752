/*------------------------------------*/
//FOOTER
/*------------------------------------*/
footer {
    display: flex;
    margin-top: auto;
    width: 100%;
    border-top: .5px solid var(--blackGreen);
    height: 330px;
    background-color: #101111;
    z-index: 998;
    .mediumContainer {
        display: flex;
        position: relative;
        padding: 0;
        border-left: 1px solid var(--blackGreen);
        &::after {
            content: '';
            position: absolute;
            width: 50px;
            height: 2px;
            background: linear-gradient(90deg, #101111 0%, #101111 25%, transparent 25%, transparent 75%, #101111 75%);
            transform: rotate(90deg);
            left: -25.5px;
            top: 40%; 
        }
    }
    .middle_footer {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        .footer_social {
            display: flex;
            justify-content: center;
            grid-gap: 1rem;
            flex-wrap: wrap;
            margin: auto;
            .footer_socialIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border: 1px solid  #FFF;
                border-radius: 50%;
                transition: border-color .25s;
                svg {
                    height: 14px;
                }
                svg path {
                    transition: color .25s;
                }
                &:hover {
                    border-color: var(--green);
                    svg path {
                        color: var(--green);
                    }
                }
            }
        }
        .footer_copyright {
            text-align: center;
            font-size: 9px;
            position: absolute;
            bottom: 2rem;
        }
    }
    .footer_img {
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;
        img {
            position: absolute;
            &:first-child {
                height: 40px;
                left: -120px;
            }
            &:last-child {
                height: 45px;
                left: 20px;
                filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(332deg) brightness(105%) contrast(101%);
            }
        }
    }
}



.footer_nav {
    z-index: 10;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 100px 0 auto;
    padding: 0 75px;
    position: relative;
    background-color: #101111;
    border-left: 1px solid var(--blackGreen);
    border-right: 1px solid var(--blackGreen);
    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 50px;
        height: 2px;
        background: linear-gradient(90deg, #101111 0%, #101111 25%, transparent 25%, transparent 75%, #101111 75%);
        transform: rotate(90deg);
    }
    &::after {
        left: -25.5px;
        bottom: 120px;  
    }
    &::before {
        right: -25.5px;
        top: 60px;
    }
    & .footer_nav_container_items {
        display: flex;
        flex-direction: column;
        grid-gap: .5rem;
        list-style: none;
        margin: 0;
        padding: 0;
        & .footer_nav_item {
            font-family: BebasNeue;
            text-transform: uppercase;
            text-align: center;
            font-size: 1.3rem;
            letter-spacing: 1px;
            & .footer_nav_item_link {
                display: inline-block;
                text-decoration: none;
                transition: .25s color;
                &.active {
                    color: var(--green);
                }
                &:hover {
                    color: var(--green);
                }
            }
        }
    }
}


@media screen and (max-width: 1000px) {
    footer .footer_nav,
    footer .footer_img {
        display: none;
    }
    footer .mediumContainer {
        border-left: unset;
    }
}