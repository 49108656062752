.wrapperBanner {
    position: relative;

    & span {
        /*Bannière d'accueil*/
        background-size: cover;
        background-position: center;
        background-image: url("../../images/banniere_final_2021_twitter.png");
        width: 100%;
        height: 600px;
        display: block;
        box-shadow: inset 0 72px 85px $black;

        @include tablet {
            height: 400px !important;
        }
        @include phone {
            height: 275px !important;
        }
    }

    &.--globalBanner span {
        /*Bannière sur l'ensemble des autres page*/
        background-image: url("../../images/banniere_2.jpg");
        min-height: 350px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: 0;

        & h1 {
            color: $ligh-bleu;
            font-weight: bold;
        }

        &::after {
            content: "";
            background: linear-gradient(to bottom, transparent 0%, $black 100%);
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            height: 85px;
        }
    }
}
