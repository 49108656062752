/*PRÉSENTATION DES DIFFÉRENTS RECRUTEMENTS*/

#recruitments .content.sbc .mainContainer .all-recruitments {
    & a {
        /*Lien de redirection sur les recrutements*/
        text-decoration: none;

        &:nth-child(0n + 2) article {
            /*Background des images*/
            background-image: url("../../images/img-jeux/CSGO.png");
            width: 1500px;
            height: 200px;
        }

        &:nth-child(0n + 3) article {
            background-image: url("../../images/img-jeux/overwatch.png");
            width: 1500px;
            height: 200px;
        }

        &:nth-child(0n + 4) article {
            background-image: url("../../images/img-jeux/valorant.png");
            width: 1500px;
            height: 200px;
        }

        &:nth-child(2n) {
            & article::after {
                /*Dégradé 1/2 par dessus la background de la présentation des différents recrutements*/
                background: linear-gradient(90deg, $black-light-opacity5 0%, $black2 74%);
                background: linear-gradient(90deg, $black2 0%, $black-light-opacity6 100%);
            }

            & p {
                /*Text de présentation des différents recrutements à droite 1/2*/
                text-align: right;
            }
        }

        &:nth-child(2n) article {
            /*Bordure 1/2 sur les articles à droite*/
            border-right: 10px solid $ligh-bleu;
            border-left: none;
        }
    }

    & article {
        /*Style présentations des différents recrutements*/
        margin: 55px 0;
        padding: 48px;
        position: relative;
        transition: 0.25s;
        background-image: url("../../images/img-jeux/lol.png");
        width: 1500px;
        height: 200px;
        background-size: cover;
        z-index: 1;
        border-left: 15px solid $ligh-bleu;

        &:hover {
            /*Effet de zoom au survole des différents recrutements*/
            transition: 0.25s;
            transform: scale(1.02);
        }

        & p {
            /*Text de présentation des différents recrutements à gauche 1/2 */
            text-align: left;
        }

        & span {
            font-weight: bold;
            font-size: 29px;
        }
    }

    & .content-recruitments {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
    }
}

#recruitments .content.sbc .mainContainer .all-recruitments article::after,
#evenements .content.sbc .mainContainer .all-evenements article:before {
    /*Dégradé par dessus le background de la présentation des différents recrutements*/
    content: "";
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    position: absolute;
    background: linear-gradient(90deg, $black2 26%, $black-light-opacity5 100%);
    background: linear-gradient(90deg, $black-light-opacity6 26%, $black2 100%);
    z-index: -1;
}
