.teamSelectwrapper {
    display: flex;
    margin-right: 1rem;

    & .teamTypeSelect {
        min-width: 50%;
        max-width: 50%;
    }

    & .teamSelect {
        min-width: 50%;
        max-width: 50%;
    }
}

.scores-container {
    margin-left: 16px;
}

.score-input {
    height: 48px;
    box-sizing: border-box;
}
