.inputWithButton {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-inputAndButton {
        display: flex;

        & input,
        & select {
            margin-right: 1em;
            min-width: 100%;
            max-width: 100%;
            &.number {
                max-width: 5em;
                min-width: 5em;
            }
        }

        &-button {
            cursor: pointer;
            margin: 1rem 0;
            display: flex;
            width: min-content;
            align-items: center;
        }
    }
    &-removeBtn {
        display: flex;
        padding: 0.5em 0;
        margin-left: 8px;

        &-btn {
            display: flex;
            box-sizing: border-box;
            justify-content: center;
            padding-top: 32px;
            cursor: pointer;

            &.score {
                width: 5em;
            }
            &.teams {
                max-width: 100%;
                min-width: 100%;
            }
        }

        &-btnCreatePlayer {
            display: flex;
            box-sizing: border-box;
            justify-content: center;

            cursor: pointer;

            &.score {
                width: 5em;
            }
            &.teams {
                max-width: 100%;
                min-width: 100%;
            }
        }
    }
}
