@mixin tablet {
    @media screen and (max-width: 1150px) {
        @content;
    }
}

@mixin phone {
    @media screen and (max-width: 450px) {
        @content;
    }
}
