.sidebar {
    display: flex;
    justify-content: stretch;
    flex: auto;
    background-color: #131313;
    &__navbar {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex: auto;
        list-style: none;
        text-align: center;
        padding: 0;
        margin: 0;

        &-li {
            display: flex;
            justify-content: center;
            height: 50px;

            &-link {
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 3em;
                flex: auto;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                &-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 2em;
                    font-size: 1.2em;
                }
                &-text {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
