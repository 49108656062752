$cyanColor: #00ffff;
$greenColor: #038058;

#shop {
    background-repeat: repeat;
    object-fit: cover;
}

.buyItems {
    max-width: 840px;
    margin: 150px auto;
}
.list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    padding: 0;
    margin: 0;
    grid-gap: 60px;
}
.content-shop {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.list li {
    width: 240px;
    list-style: none;
    margin: auto;
    position: relative;
}
.list li::after,
.list li::before {
    content: "";
    position: absolute;
    width: 2px;
    background-color: #038058;
    height: 50%;
    top: 25%;
}
.list li::before {
    right: 0;
}
.list li p {
    margin: 0;
}
.list li strong {
    display: block;
    text-align: center;
    margin: 10px;
}
.itemsFirst img {
    width: 200px;
    height: 245px;
}
.itemsHover {
    display: none;
}
.itemsFirst:hover .items {
    display: none;
}
.itemsFirst:hover .itemsHover {
    display: block;
}
.optionsItemsLeft,
.optionsItemsRight {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
}
.actionItemsRight,
.actionItemsLeft {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding: 20px 0;
}
.logoShopRight,
.logoShopLeft {
    width: 50px;
    height: 50px;
    margin-top: auto;
}
.linkDetails,
.linkAdd {
    border: 2px solid #24b07d;
    background-color: #24b07d;
    color: black;
    cursor: pointer;
    display: flex;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.25s;
    align-items: center;
    justify-content: center;
    & svg {
        margin-right: 5px;
        & path {
            fill: black;
            transition: fill 0.25s;
        }
    }
    &:hover {
        color: #fff;
        background-color: transparent;
        & path {
            fill: #fff;
        }
    }
}

.newCollection .list li:nth-child(1) .containerLogoBottomItems img,
.newCollection .list li:nth-child(3) .containerLogoBottomItems img,
.newCollection .list li:nth-child(4) .containerLogoBottomItems img,
.newCollection .list li:nth-child(6) .containerLogoBottomItems img,
.newCollection .list li:nth-child(7) .containerLogoBottomItems img {
    display: none;
}
.containerLogoBottomItems {
    display: flex;
}
.containerLogoBottomItems img {
    width: 50px;
    height: 50px;
}
.newCollection .containerLogoBottomItems img:last-child {
    transform: scaleX(-1);
    margin-left: auto;
}
.vipItems .list li:nth-child(1) .containerLogoBottomItems img {
    display: block !important;
}
.oldItems.buyItems {
    max-width: 1140px;
}
.oldItems .list li:nth-child(3) .containerLogoBottomItems img,
.oldItems .list li:nth-child(4) .containerLogoBottomItems img {
    transform: scaleX(-1);
    margin-left: auto;
}

.bannerImg {
    overflow: hidden;
    position: relative;
    background-size: cover;
    overflow: hidden;
    & .firstBannerImg {
        height: 640px;
        background: url("../../images/banniere-charte-graphique/images4k.jpg");
        background-size: cover;
    }
    &.secondBannerImg {
        height: 830px;
        background: url("../../images/banniere-charte-graphique/image24kSansTeeShirt.jpg");
        background-size: cover;
        &::after {
            content: "";
            position: absolute;
            height: 830px;
            width: 100%;
            background: url("../../images/banniere-charte-graphique/image24kTeeShirt.png");
            background-size: cover;
            animation: mooveTeeShirt 5s ease-in-out infinite;
            animation-direction: alternate;
        }
        & .containerBtnBanner {
            position: absolute;
            display: flex;
            justify-content: space-between;
            left: 311px;
            height: 37px;
            bottom: 123px;
            width: 364px;
            z-index: 20;
            & a {
                letter-spacing: 1px;
                text-align: center;
                width: 100px;
                font-family: BebasNeue;
                font-size: 19px;
                border: 2px solid gray;
                padding: 5px 15px;
                text-decoration: none;
                background-color: #1a1a1a;
                transition:
                    border-color 0.25s,
                    color 0.25s;
                cursor: pointer;
                &:hover {
                    border-color: var(--green);
                    color: var(--green);
                }
            }
        }
    }
    &.thirdBannerImg {
        height: 1620px;
        background: url("../../images/banniere-charte-graphique/imageShootingLow.jpg");
        background-size: cover;
    }
    &.fourBanner {
        height: 644px;
        background: url("../../images/banniere-charte-graphique/bannerShop4Light.jpg");
        background-size: cover;
        & span {
            position: absolute;
            height: 644px;
            width: 100%;
            opacity: 0;
            transform: translateY(-20px);
            &.animation {
                animation: banner4Apparition 1s;
                -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
                -moz-animation-fill-mode: forwards; /* FF 5+ */
                -o-animation-fill-mode: forwards; /* Not implemented yet */
                -ms-animation-fill-mode: forwards; /* IE 10+ */
                animation-fill-mode: forwards; /* When the spec is finished */
            }
            &:nth-child(1) {
                background: url("../../images/banniere-charte-graphique/bannerShop4_items1.png");
                background-size: cover;
                animation-direction: alternate;
                z-index: 5;
            }
            &:nth-child(2) {
                background: url("../../images/banniere-charte-graphique/bannerShop4_items2.png");
                background-size: cover;
                animation-direction: alternate;
                z-index: 4;
                animation-delay: 0.25s;
            }
            &:nth-child(3) {
                background: url("../../images/banniere-charte-graphique/bannerShop4_items3.png");
                background-size: cover;
                animation-direction: alternate;
                z-index: 3;
                animation-delay: 0.5s;
            }
            &:nth-child(4) {
                background: url("../../images/banniere-charte-graphique/bannerShop4_items4.png");
                background-size: cover;
                animation-direction: alternate;
                z-index: 2;
                animation-delay: 0.75s;
            }
            &:nth-child(5) {
                background: url("../../images/banniere-charte-graphique/bannerShop4_items5.png");
                background-size: cover;
                animation-direction: alternate;
                z-index: 1;
                animation-delay: 1s;
            }
            &:nth-child(6) {
                background: url("../../images/banniere-charte-graphique/bannerShop4_items6.png");
                background-size: cover;
                animation-direction: alternate;
                animation-delay: 1.25s;
            }
        }
    }
}

@keyframes mooveTeeShirt {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(50px);
    }
}

@keyframes banner4Apparition {
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.buyItemsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 100px;
    max-width: 1100px;
    margin: 100px auto;
    article.buyItem {
        width: 300px;
        padding-bottom: 60px;
        overflow: hidden;
        position: relative;
        margin: auto;
        .buyItems_Header {
            img {
                width: 300px;
                height: 454px;
                transition: filter 0.5s;
            }
        }
        &.active img {
            filter: blur(4px);
        }
        &.active .buyItems_footer {
            transform: translateY(-98px);
            .buyItems_footerArrow svg {
                transform: rotate(0);
            }
        }
        .buyItems_footer {
            position: absolute;
            width: 100%;
            background-color: #0e0e0e;
            padding: 15px 20px;
            transition: transform 0.5s;
            .buyItems_footerArrow {
                background-color: #0e0e0e;
                width: 90px;
                height: 40px;
                border-radius: 50% 50% 0 0;
                position: absolute;
                top: -25px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                svg {
                    width: 25px;
                    fill: #fff;
                    margin-top: 2px;
                    transform: rotate(-180deg);
                    transition: transform 0.5s;
                }
            }
            .buyItems_footerName {
                font-family: BebasNeue;
                font-size: 22px;
                letter-spacing: 1px;
                text-align: center;
            }
            .buyItems_footerContainerBtn {
                padding-top: 60px;
                display: flex;
                justify-content: space-between;
                .buyItems_footerBtn {
                    letter-spacing: 1px;
                    text-align: center;
                    padding: 5px 10px 2px;
                    background-color: transparent;
                    border: 1px solid gray;
                    font-family: BebasNeue;
                    text-decoration: none;
                    width: 115px;
                    transition:
                        color 0.25s,
                        border-color 0.25s;
                    &:hover {
                        color: var(--green);
                        border-color: var(--green);
                        svg path {
                            color: var(--green);
                        }
                    }
                    svg {
                        font-size: 14px;
                        path {
                            transition: color 0.25s;
                        }
                    }
                }
            }
        }
    }
}

.containerNewCollection_2023 {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    img {
        height: 200px;
    }
}

.img_thirdBannerShop {
    max-width: 1620px;
    width: 100%;
}
