/* team */

.tab-pane {
    display: none;
    opacity: 1;
    transition: opacity 1s linear;

    & .teamWrapper {
        &:nth-child(2n + 1) {
            & .teamBorder {
                border-left: 1px solid #1dfef6;

                @include tablet {
                    border-left: none;
                }
            }
        }
        &:nth-child(2n) {
            & .teamBorder {
                border-right: 1px solid #1dfef6;

                @include tablet {
                    border-right: none;
                }
            }
        }
    }
    & div {
        &:nth-child(2n) {
            & .team-tab {
                flex-direction: row !important;
                border-left: 5px $ligh-bleu solid;

                @include tablet {
                    border-left: none;
                    flex-direction: column-reverse !important;
                }
            }
        }

        &:nth-child(2n + 1) {
            & .team-tab {
                border-right: 5px $ligh-bleu solid;

                @include tablet {
                    border-right: none;
                }
            }
        }

        & .team-tab {
            display: block;
            position: relative;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            margin-bottom: 30px;
            background-size: cover;

            @include tablet {
                display: block;
                position: relative;
                flex-direction: column-reverse;
                flex-wrap: wrap;
                margin-bottom: 30px;
                background-size: cover;
            }

            & .description {
                justify-content: center;
                width: 30%;
                margin: auto;

                @include tablet {
                    justify-content: center;
                    width: 75%;
                    margin: 10px auto 30px;
                    text-align: center;
                }
            }

            &::before {
                content: "";
                background-color: linear-gradient(90deg, $black-light-opacity4 26%, $black2 100%) !important;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                position: absolute;
                z-index: -1;
                flex-wrap: wrap;
                margin-bottom: 30px;
                background-size: cover;
            }
        }
    }
}

.all-recruitments-team {
    justify-content: center;
    flex-wrap: wrap;

    & a {
        transition: 0.25s;

        &:hover img {
            transform: scale(1.25);
            transition: 0.25s;
        }
    }

    & .hexagon {
        position: relative;
        width: 150px;
        height: 86.6px;
        background-color: $ligh-bleu;
        margin: 43.3px 30px;
        transition: 0.25s;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 0;
            border-left: 75px solid transparent;
            border-right: 75px solid transparent;
        }

        &:before {
            bottom: 100%;
            border-bottom: 43.3px solid $ligh-bleu;
        }

        &:after {
            top: 100%;
            width: 0;
            border-top: 43.3px solid $ligh-bleu;
        }

        &.small-back {
            position: absolute;
            top: -38px;
            left: 5px;
            width: 140px;
            height: 80.83px;
            background-color: #111;
            margin: 40.41px 0;
            display: flex;
            justify-content: center;
            align-items: center;

            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 0;
                border-left: 70px solid transparent;
                border-right: 70px solid transparent;
            }

            &:before {
                bottom: 100%;
                border-bottom: 40.41px solid #111;
                border-bottom: 59.41px solid #111;
            }

            &:after {
                top: 100%;
                width: 0;
                border-top: 40.41px solid #111;
                border-top: 59.41px solid #111;
                z-index: 1;
            }
        }

        & img {
            max-width: 75px;
            position: absolute;
            z-index: 9;
            transition: 0.25s;
        }
    }
}

.rosterTeam,
.equipe-team,
.manager-team {
    & .hexagon img {
        width: 100px;
        height: 100px;
        z-index: 10;
    }
}

/* down part */

#team .spacer {
    width: 80%;
    margin: 50px auto;
    height: 2px;
    background: linear-gradient(90deg, #1dfef6 0%, #ffffff 50%, #1dfef6 100%);
}

#team .manager-team {
    display: flex;
    flex-direction: column;
    width: 15%;
    margin: auto 0;
    padding-right: 20px;

    @include tablet {
        display: flex;
        justify-content: center;
        width: 50%;
        border-bottom: 2px solid #1dfef6;
        margin: 0 auto;
        flex-direction: row;
        padding-right: 0;
    }

    @include phone {
        display: block;
        width: 50%;
        border-bottom: 2px solid #1dfef6;
        margin: 0 auto;
        padding-right: 0;
    }
}

.recruitments-team {
    flex-direction: column;

    & > div {
        margin: 10px 0;
        width: 100%;
        border-radius: 25px;
        z-index: 2;
    }

    & p {
        text-align: justify;
    }
}

.recruitments-team .col-md-6.d-flex,
.roster .rosterTeam {
    flex-wrap: wrap;
    justify-content: center;
}

.recruitments-team .hexagon,
.roster .hexagon {
    position: relative;
    width: 120px;
    height: 69.28px;
    background-color: $ligh-bleu;
    margin: 50px 50px 80px 50px;
}

.roster .hexagon {
    margin: 30px 30px 80px 30px;
}

.recruitments-team .hexagon:before,
.hexagon:after,
.roster .hexagon:before,
.roster .hexagon:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
}

.recruitments-team .hexagon:before,
.roster .hexagon:before {
    bottom: 100%;
    border-bottom: 34.64px solid $ligh-bleu;
}

.recruitments-team .hexagon:after,
.roster .hexagon:after {
    top: 100%;
    width: 0;
    border-top: 34.64px solid $ligh-bleu;
}

.recruitments-team .hexagon.small-back,
.roster .hexagon.small-back {
    position: relative;
    width: 115px;
    height: 66.4px;
    background-color: $black;
    margin: 33.2px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 2.4px;
    top: -32px;
}

.recruitments-team .hexagon.small-back:before,
.recruitments-team .hexagon.small-back:after,
.roster .hexagon.small-back:before,
.roster .hexagon.small-back:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 57.5px solid transparent;
    border-right: 57.5px solid transparent;
}

.recruitments-team .hexagon.small-back:before,
.roster .hexagon.small-back:before {
    bottom: 100%;
    border-bottom: 33.2px solid $black;
}

.recruitments-team .hexagon.small-back:after,
.roster .hexagon.small-back:after {
    top: 100%;
    width: 0;
    border-top: 33.2px solid $black;
    z-index: 1;
}

.hexagon .name {
    position: absolute;
    width: 200%;
    text-align: center;
    color: $ligh-bleu;
    bottom: -78px;
    left: -50%;

    @include tablet {
        margin-bottom: 0;
    }
}
.hexagon .socialNetwork,
.hexagon .socialNetworkCoach {
    display: flex;
    justify-content: space-around;
}

.hexagon .socialNetworkCoach {
    position: relative;
    top: 65px;
}

.roster {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: space-between;

    @include tablet {
        display: block;
    }
}
.d-flex {
    display: flex !important;
}
.recruitments-team .col-md-6.d-flex,
.roster .rosterTeam {
    flex-wrap: wrap;
    justify-content: center;
}

.localTeam,
.otherTeam {
    flex: 41.666667%;
    max-width: 41.666667%;

    @include tablet {
        max-width: none !important;
    }
}
/* nav bootstrap */

#team .nav-tabs .nav-link,
#team .nav-tabs,
.nav-tabs .nav-link.active {
    border: none;
    padding: 0;
    background-color: transparent;
}

#team #tab-team {
    justify-content: center;
    border: none;
    list-style-type: none;
    padding-left: 0;
    display: flex;
}

.manager-team .hexagon {
    @include phone {
        margin: 50px auto 140px;
    }

    & .hexagon.small-back {
        margin: 0;
        top: 0;
    }
}

@include phone {
    .hexadeux {
        margin: 50px auto 115px !important;
    }
}

// .fade {
//     display: none;
// }
