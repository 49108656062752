.titleAndSubtitle {
    text-align: center;
    margin: 10px 0;

    & h2 {
        color: $ligh-bleu;
        font-weight: bold;
        font-size: 2.5rem;
        // margin: 0;
    }

    & p {
        width: 80%;
        margin: 0 auto;
        font-size: 1rem;
    }

    & h6 {
        font-size: 1rem;
    }
}
