.allContainerGraphic {
    position: relative;
    top: 300px;
    z-index: 1;

    & .containerLogo {
        width: 100%;
        margin: 0 0 16px 0;
        padding: 0;
        display: flex;
        justify-content: center;

        & .sectionLogo {
            position: relative;
            width: 70%;

            @include phone {
                width: 95%;
            }

            & .divImgBannier {
                position: relative;
                text-align: center;
                z-index: 40;

                & img {
                    width: 100%;
                }
            }

            & .CharteDropDown {
                overflow: hidden;
                background: linear-gradient(#001b11, #000);
                transition: all 0.5s ease;
                height: 0;
                z-index: 20;
                display: flex;
                position: relative;
                top: -4px;
                justify-content: center;
                flex-direction: column;
                padding: 0 5%;

                & a {
                    position: relative;
                    height: 90px;
                    text-decoration: none;
                    width: 48%;
                    margin: 0 0 16px 0;
                    border: 2px solid white;
                    border-radius: 10px;
                    transition: 0.4s;
                    display: flex;
                    background-color: transparent;

                    @include tablet {
                        width: 100%;
                        height: 60px;
                    }

                    @include phone {
                        height: 40px;
                    }

                    &:hover {
                        transform: scale(1.04);
                    }

                    & img {
                        height: 80px;
                        border-radius: 10px;
                        padding: 1%;

                        @include tablet {
                            height: 50px;
                        }

                        @include phone {
                            height: 30px;
                        }
                    }

                    & .titleDownload {
                        height: 100%;
                        margin: 0 0 0 5%;
                        font-size: 30px;
                        color: white;
                        display: flex;
                        align-items: center;

                        @include tablet {
                            font-size: 18px;
                        }

                        @include phone {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
