#container-login-form {
    & .fieldLoginAdmin {
        margin-bottom: 1rem;

        & label {
            display: inline-block;
            margin-bottom: 0.5rem;
        }

        & .stateLoginForm {
            display: block;
            width: 100%;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            box-sizing: border-box;
            min-width: unset;
            max-width: unset;
            appearance: none;

            &:focus,
            &:focus-visible {
                border-color: #2b9914;
                box-shadow: 0 0 0 0.2rem rgba(104, 187, 4, 0.25);
                outline: none;
            }
        }
    }

    & .btnPrimary {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
    }

    .btnPrimary:hover {
        color: #fff;
        background-color: #0069d9;
        border-color: #0062cc;
    }

    [type="button"],
    [type="reset"],
    [type="submit"],
    button {
        -webkit-appearance: button;
    }

    button,
    input {
        overflow: visible;
    }

    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled),
    button:not(:disabled) {
        cursor: pointer;
    }
}
