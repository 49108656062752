.adminHeaderTop {
    height: 5em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1em;
    background-color: black;

    &__btnDeco {
        cursor: pointer;

        &:hover {
            color: #963838;
            text-decoration: underline;
        }
    }
}
