


#home_DVG {
    .home_DVG_TOP {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
        .home_DVG_TOP_item {
            height: 610px;
            position: relative;
            border-radius: 1rem;
            .white_btn {
                position: absolute;
                left: 2rem;
                bottom: 2rem;
            }
            &:first-child {
                grid-area: 1 / 1 / 1 / 3;
            }
            &.slider_home {
                height: 390px;
                grid-area: 2 / 1 / 2 / 4;
                overflow: hidden;
                .slider_images_container {
                    height: 100%;
                    .slider_img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        object-fit: cover;
                        transition: transform .6s, opacity .6s;
                        &:nth-child(1) {
                            transform: translateY(30px) translateX(50px);
                        }
                        &:nth-child(3) {
                            transform: translateY(-30px) translateX(-50px);
                        }
                        &:nth-child(3) {
                            transform: translateY(0) translateX(50px);
                        }
                        &.active {
                            opacity: 1;
                            transform: translateY(0) translateX(0);
                        }
                    }
                }
                .slider_arrow_navigation_container {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    display: flex;
                    justify-content: space-between;
                    padding: 0 75px;
                    width: 100%;
                    .slider_arrow {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        border-left: 3px solid var(--white);
                        border-top: 3px solid var(--white);
                        cursor: pointer;
                        &.left {
                            transform: rotate(-45deg);
                        }
                        &.right {
                            transform: rotate(135deg);
                        }
                    }
                }
                .slider_point_navigation_container {
                    display: flex;
                    justify-content: center;
                    grid-gap: .3rem;
                    height: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    span {
                        margin: auto 0 .8rem 0;
                        display: block;
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                        background-color: var(--gray);
                        &.active {
                            background-color: var(--white);
                        }
                    }
                }
            }
        }
    }

    .separatorLine {
        height: 80px;
        margin: 80px 0;
        border-top: 1px solid var(--blackGreen);
        border-bottom: 1px solid var(--blackGreen);
        & .separatorLineContainer {
            display: flex;
            align-items: center;
            max-width: 1903px;
            margin: 0 auto;
            height: 100%;
            position: relative;
            & .separatorItem {
                width: 100px;
                height: 25px;
            }
            & .car {
                position: absolute;
                animation: car 8s infinite linear;
            }
            @keyframes car {
                0% {
                    top: -10px;
                    left: -200px;
                    transform: rotate(180deg);
                }
                15% {
                    top: -10px;
                    left: 85%;
                    transform: rotate(180deg);
                }
                25% {
                    top: -100px;
                    left: 90%;
                    transform: rotate(90deg);
                    transform-origin: left;
                }
                32.5% {
                    top: -50px;
                    left: 85%;
                    transform: rotate(-45deg);
                    transform-origin: left;
                }
                40% {
                    top: -10px;
                    left: 70%;
                    transform: rotate(0deg);
                }
                55% {
                    top: -10px;
                    left: -200px;
                    transform: rotate(0deg);
                }
                100% {
                    top: -10px;
                    left: -200px;
                    transform: rotate(0deg);
                }
            }
        }
    }

    .home_DVG_MIDDLE {
        display: flex;
        grid-gap: 1rem;
        margin-bottom: 80px;
        position: relative;
        .left {
            width: 770px;
            & .big_title {
                position: relative;
                & .date {
                    background-color: #101111;
                    padding:  0 1rem;
                    margin-left: -1rem;
                }
                & .border_decoration_2017 {
                    position: absolute;
                    bottom: -66px;
                    left: -343px;
                    height: 100px;
                    width: 810px;
                    border-top: 1px solid var(--blackGreen);
                    z-index: -1;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 40px;
                        height: 2px;
                        background: linear-gradient(90deg, #101111 0%, #101111 15%, transparent 15%, transparent 85%, #101111 85%);
                        // transform: rotate(90deg);
                        left: 200px;
                        top: -1px;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        width: 40px;
                        height: 2px;
                        background: linear-gradient(90deg, #101111 0%, #101111 15%, transparent 15%, transparent 85%, #101111 85%);
                        // transform: rotate(90deg);
                        left: 700px;
                        top: -1px;
                    }
                    & .left_square {
                        height: 100px;
                        width: 110px;
                        border-bottom: 1px solid var(--blackGreen);
                        border-right: 1px solid var(--blackGreen);
                        background-color: #101111;
                        margin-top: -1px;
                    }
                }
            }
            & .container_description {
                p {
                    text-align: justify;
                }
            }
        }
        .right {
            display: flex;
            width: 100%;
            grid-gap: 1rem;
            .length_item {
                min-width: 326px;
                height: 100%;
                border-radius: 1rem;
            }
            .container_right_items {
                display: flex;
                flex-direction: column;
                grid-gap: 1rem;
                width: 100%;
                position: relative;
                .square_item {
                    height: 380px;
                    border-radius: 1rem;
                    &:last-child {
                        height: 350px;
                    }
                }
                .circle {
                    position: absolute;
                    animation: animationCircle 15s linear infinite;
                    top: 65px;
                    left: 35px;
                    width: 530px;
                }
            }
        }
    }
    .middle_widget {
        height: 80px;
        width: 35px;
        border: 1px solid var(--blackGreen);
        border-radius: 1rem;
        margin: 1.5rem auto;
        padding: 5px 0;
        span {
            display: block;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            margin: 0 auto;
            background-color: var(--green);
            animation: roundBottom 2s ease-in-out infinite;
        }
    }
    .home_DVG_Article_container_title {
        display: flex;
        & .left {
        }
        & .right {

        }
    }
    .home_DVG_video {
        .top {
            text-align: center;
        }
    }
    .home_DVG_Article_container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 4rem;
        margin-top: 40px;
        .article {
            display: grid;
            grid-template-rows: repeat(2, 1fr);
            height: 600px;
            border-radius: 1rem;
            overflow: hidden;
            .article_top {
                background-color: rgb(61, 61, 61);
                border-radius: 1rem 1rem 0 0;
            }
            .article_bottom {
                display: flex;
                flex-direction: column;
                padding: 1.8rem;
                background-color:var(--white);
                p {
                    margin: 0;
                }
                .article_author_date_container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .author {
                        font-family: BebasNeue;
                        font-size: 30px;
                        text-transform: uppercase;
                        color: var(--green);
                    }
                    .date {
                        font-size: 20px;
                        color: var(--black);
                    }
                }
                .title {
                    padding: 1rem 0;
                    font-family: BebasNeue;
                    font-size: 35px;
                    line-height: 42px;
                    color: var(--black);
                }

            }
        }
    }
    .home_DVG_Article_decoration_bottom {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 4rem;
        height: 150px;
        .item {
            height: 75px;
            position: relative;
            &:first-child {
                border-right: 1px solid var(--blackGreen);
                border-bottom: 1px solid var(--blackGreen);
                transform: translateX(-50%);
                &::after {
                    content: '';
                    position: absolute;
                    width: 30px;
                    height: 2px;
                    background: linear-gradient(90deg, #101111 0%, #101111 15%, transparent 15%, transparent 85%, #101111 85%);
                    right: 80px;
                    bottom: -2px;
                }
            }
            &:nth-child(2) {
                display: flex;
                justify-content: center;
                height: 120px;
                .line {
                    position: relative;
                    height: 100%;
                    width: 1px;
                    background-color: var(--blackGreen);
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100px;
                        height: 2px;
                        background: linear-gradient(90deg, transparent 90%, #101111 90%);
                        left: -50px;
                        top: 55px;
                        transform: rotate(90deg);
                    }
                }
                .icon {
                    position: absolute;
                    left: 50%;
                    top: 20px;
                    transform: translateX(-50%);
                    padding: 10px 0;
                    background-color: var(--lightBlack);
                }
            }
            &:last-child {
                border-left: 1px solid var(--blackGreen);
                border-bottom: 1px solid var(--blackGreen);
                transform: translateX(50%);
                &::after {
                    content: '';
                    position: absolute;
                    width: 30px;
                    height: 2px;
                    background: linear-gradient(90deg, #101111 0%, #101111 15%, transparent 15%, transparent 85%, #101111 85%);
                    left: 80px;
                    bottom: -2px;
                }
            }
        }
    }
    .item_home_effect_hover {
        position: relative;
        overflow: hidden;
        &::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            box-shadow: inset 0 0 0 0 var(--blackGreen);
            z-index: 1;
            border-radius: 1rem;
            transition: box-shadow .25s;
        }
        &:hover::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            box-shadow: inset 0 0 0 1px var(--blackGreen);
            z-index: 1;
            border-radius: 1rem;
        }
        img {
            width: 100%;
            height: 100%;
            transition: transform .5s;
            position: relative;
            object-fit: cover;
        }
        &:hover img {
            transform: scale(1.05);
        }
    }

    @keyframes animationCircle {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes roundBottom {
        from {
            transform: translateY(0px);
            opacity: 1;
        }
        to {
            transform: translateY(55px);
            opacity: 0;
        }
    }
}

@media screen and (max-width: 600px) {
    #home_DVG .home_DVG_TOP {
        grid-template-columns: repeat(1, 1fr);
    }
    #home_DVG .home_DVG_TOP .home_DVG_TOP_item:first-child {
        grid-area: unset;
    }
    #home_DVG .home_DVG_TOP .home_DVG_TOP_item.slider_home {
        grid-area: unset;
        height: 190px;
    }
    #home_DVG .home_DVG_TOP .home_DVG_TOP_item {
        height: 280px;
    }
}

@media screen and (max-width: 1300px) {
    #home_DVG .home_DVG_Article_container {
        grid-template-columns: repeat(4, 1fr);
    }
    #home_DVG .home_DVG_Article_container .article:nth-child(1) {
        grid-area: 1 / 1 / 1 / 3;
    }
    #home_DVG .home_DVG_Article_container .article:nth-child(2) {
        grid-area: 1 / 3 / 1 / 5;
    }
    #home_DVG .home_DVG_Article_container .article:nth-child(3) {
        grid-area: 2 / 2 / 2 / 4;
    }
    #home_DVG .home_DVG_Article_decoration_bottom .item:first-child,
    #home_DVG .home_DVG_Article_decoration_bottom .item:last-child {
        display: none;
    }
    #home_DVG .home_DVG_Article_decoration_bottom .item:nth-child(2) {
        grid-area: 1 / 2 / 1 / 2;
    }
}

@media screen and (max-width: 800px) {
    #home_DVG .home_DVG_Article_container {
        grid-template-columns: repeat(1, 1fr);
    }
    #home_DVG .home_DVG_Article_container .article:nth-child(1),
    #home_DVG .home_DVG_Article_container .article:nth-child(2),
    #home_DVG .home_DVG_Article_container .article:nth-child(3) {
        grid-area: unset;
    }
    #home_DVG .home_DVG_Article_container_title .left {
        text-align: center;
        width: 100%;
    }
    #home_DVG .home_DVG_Article_container_title .right {
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    #home_DVG .home_DVG_MIDDLE {
        display: block;
        & .left {
            width: 100%;
            text-align: center;
            & .container_description p {
                text-align: center;
            }
        }
        & .right {
            flex-direction: column;
            position: relative;
            margin-top: 115px;
            & .length_item {
                height: 500px;
            }
            & .container_right_items  {
                position: unset;
                & .circle {
                    top: -10%;
                    left: -10%;
                    width: 120%;
                    z-index: -1;
                }
                & .square_item,
                & .square_item:last-child {
                    height: 250px;
                }
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .border_decoration_2017 {
        display: none;
    }
}